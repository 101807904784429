import React from 'react'
import { Layout } from 'antd'
import Header from './components/Header'
import About from './components/About'
import Projects from './components/Projects'
import Footer from './components/Footer'
import ResearchInterest from './components/ResearchInterest'
import Music from './components/Music'
import Movies from './components/Movies'
import Details from "./components/Details"
import Games from "./components/Games"
import CV from "./components/CV"

import { ConfigProvider, Divider } from 'antd'

import projectList from './data/projectList'
import researchInterestList from './data/researchInterestList'
import AlbumList from './data/albumList'
import MovieList from './data/movieList'
import GameList from './data/gameList'

const { Content } = Layout

const App = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: '8px',
          colorPrimary: 'var(--primary-color)',
          colorBgLayout: 'var(--senary-color)',
          colorBgContainer: 'var(--septary-color)',
        },
      }}
    >
      <Layout style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <Header /> {/*Link to my old page*/}
        <Content style={{ flex: 1, padding: '16px', marginTop: '32px' }}>
          <About />
          <ResearchInterest interests={researchInterestList} />
          <Projects projects={projectList} />
          <CV />
          <div>
            <Details title="Expand for some personal facts...">
              <Music albums={AlbumList} />
              <Divider style={{ borderColor: 'var(--secondary-color)' }} />
              <Movies movies={MovieList} />
              <Divider style={{ borderColor: 'var(--secondary-color)' }} />
              <Games games={GameList} />
            </Details>
          </div>
        </Content>
        <footer style={{ textAlign: 'center', padding: '16px', backgroundColor: 'var(--senary-color)', color: 'var(--primary-color)' }}>
          <p>
          This website is non-commercial and uses Google Analytics to monitor usage. Data collected may include anonymized information such as browser type, general location, and interactions with the site. No personal data is sold or shared with third parties.
          </p>
        </footer>
        <Footer />
      </Layout>
    </ConfigProvider>
  )
}

export default App
